import { Icon, IconsEnum } from '../Icons';
import { memo, useState } from 'react';

import { Category } from 'types/categoryEnum';
import { ItemWithTranslation } from 'types/swagger';
import { SearchBar } from '../searchBar';
import { SlotCardType } from '../cards/slotCard';
import styles from './HeaderTitleWithSearch.module.scss';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetLabelByKey, useTranslation } from 'hooks/useLingUI';
import classNames from 'classnames';

export type HeaderTitleWithSearchProps = ItemWithTranslation<{
  title: string;
  titleHighlighted?: string;
}> & {
  category: Category;
  labelSearch?: string | null | undefined;
  hasPaddingLeft?: boolean;
  cardChoseFromSnai?: Array<SlotCardType> | null;
  isOnlyDesktop?: boolean;
};

export const HeaderTitleWithSearch = memo(function HeaderTitleWithSearch({
  title,
  titleTrKey,
  category,
  labelSearch,
  hasPaddingLeft,
  titleHighlighted,
  cardChoseFromSnai,
  titleHighlightedTrKey,
  isOnlyDesktop = false,
}: HeaderTitleWithSearchProps) {
  const { t } = useTranslation();
  const [isSearchOpened, setIsSearchOpened] = useState<boolean>(false);
  const researchPlaceholder = useGetLabelByKey('search_placeholder')!;

  function handleChange(state: boolean) {
    setIsSearchOpened(state);
  }
  useBlockScroll(isSearchOpened);

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.paddingLeft]: hasPaddingLeft },
        { [styles.hidden]: isOnlyDesktop }
      )}
      suppressHydrationWarning
    >
      <strong suppressHydrationWarning className={styles.title}>
        {t(titleTrKey, title)}
        &nbsp;
        {titleHighlighted && (
          <span suppressHydrationWarning className={styles.titleHighlighted}>
            {t(titleHighlightedTrKey, titleHighlighted)}
          </span>
        )}
      </strong>
      {labelSearch && (
        <button
          type="button"
          onClick={() => setIsSearchOpened(true)}
          aria-label={IconsEnum.SEARCH}
          suppressHydrationWarning
          className={styles.buttonSearch}
        >
          <Icon iconId={IconsEnum.SEARCH} className={styles.iconSearch} color="var(--color-on-bg-primary)"></Icon>
          <span suppressHydrationWarning>{labelSearch}</span>
        </button>
      )}
      <SearchBar
        title={title}
        category={category || title}
        isMenuOpened={isSearchOpened}
        handleCloseMenu={handleChange}
        choseFromSnaiSlot={cardChoseFromSnai}
        inputPlaceholder={researchPlaceholder}
      />
    </div>
  );
});

import { openCenterModal } from 'lib/launchGame/utils';
import { useCallback } from 'react';
import { getFantawinnerGameUrl } from './actions';
import { useAppDispatch } from 'lib/centralStore';

export const useLaunchFantawinner = () => {
  const dispatch = useAppDispatch();

  const launchFantawinner = useCallback(
    async (height: number, width: number) => {
      try {
        const { url } = await dispatch(getFantawinnerGameUrl()).unwrap();
        if (url) openCenterModal({ url: url, heightSize: height, widthSize: width });
      } catch (error) {
        console.error('Errore durante il lancio', error);
      }
    },
    [dispatch]
  );

  return { launchFantawinner };
};

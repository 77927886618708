import { GetStaticProps, InferGetStaticPropsType } from 'next';

import { Layout } from 'components/Layout';
import PageContent from 'components/page-content/giochi-di-carte/PageContent';
import { Seo } from 'components/seo';
import { cleanConfig } from 'lib/datoCms/utils';
import { getCardGamesTopPage } from 'lib/datoCms/queries/getCardGamesTopPage';
import { getDatoCMSData } from 'sites/snai/lib/datoCms/queries/getDatoCMSData';
import { ISR_REVALIDATE } from 'utility/constant';
import { getCardsFromSearchField } from 'lib/ssr/search/search';
import { Category } from 'types/categoryEnum';

const GiochiDiCarte = ({
  site,
  seoMetaTags,
  canonical,
  header,
  needHelp,
  footer,
  cardGamesTopPage,
  allMightLikes,
  choseFromSnaiCardSearch,
  sidebarInstantGame,
}: InferGetStaticPropsType<GetStaticProps>) => {
  return (
    <Layout
      header={header}
      needHelp={needHelp}
      footer={footer}
      mightLikes={allMightLikes}
      sidebarInstantGame={sidebarInstantGame}
    >
      <Seo siteSeo={site} metaTags={seoMetaTags} canonicalUrl={canonical} />
      <PageContent cardGamesTopPage={cardGamesTopPage} choseFromSnaiCardSearch={choseFromSnaiCardSearch} />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  try {
    const {
      site,
      header,
      footer,
      needHelp,
      allLabels: labels,
      sidebarInstantGame,
      config,
    } = await getDatoCMSData('common', locale);

    const prPageData = getCardGamesTopPage(locale);

    const [pageData] = await Promise.all([prPageData]);

    const { cardGamesTopPage, allMightLikes } = pageData ?? {};
    const { seoMetaTags, canonical, searchField } = cardGamesTopPage ?? {};

    const cards = await getCardsFromSearchField(searchField ?? [], Category.CardGame);

    return {
      props: {
        site,
        header,
        footer,
        labels,
        config: cleanConfig(config),
        needHelp,
        canonical,
        seoMetaTags,
        sidebarInstantGame,
        allMightLikes,
        cardGamesTopPage,
        choseFromSnaiCardSearch: cards ?? null,
      },
      revalidate: ISR_REVALIDATE,
    };
  } catch (error) {
    console.log('error', error);
    return { notFound: true };
  }
};

export default GiochiDiCarte;
